.fotosMain {
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  max-width: 1250px;
}

.fotosMainError {
  display: flex;
  flex-direction: column;
  width: 90vw;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  max-width: 1250px;
}

.fotosFoto {
  text-align: center;
  max-width: 1250px;
  margin: auto;
}

.image {
  width: 80%;
}

.errorImage {
  margin-top: 30px;
  height: 200px;
}

@media only screen and (max-width: 1000px) {
  .fotosMain {
    flex-direction: column;
  }

  .fotosFoto {
    width: 50%;
  }
}